import React, { useState, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import { Text, Button, TileWrapper, Input, View, analytics } from 'lib'
import { makeStyles, withStyles } from '@material-ui/styles'
import { Grid, Checkbox } from '@material-ui/core'
import firebase from 'gatsby-plugin-firebase'
import { Capacitor } from '@capacitor/core'
import { trackAppsFlyerEvent } from 'utils/analytics'

const Signup = (props) => {
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [isSubmitted, setSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [valid, setValid] = useState({
    email: true,
    password: true,
    passwordMatch: true,
  })
  const validPasswordRegex = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/)


  const styles = useStyles()

  const { email, firstName, lastName, country, USState, referralCode, businessName, phoneNumber } = (props.location.state || {})

  useEffect(() => {
    if (!email || !firstName || !lastName || !country) navigate('/signup')
  }, [])

  const onSignUp = async () => {
    setIsLoading(true)

    analytics.track('Complete Sign-up Button')

    try {
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password)
      const user = userCredential.user

      await user.sendEmailVerification()

      const serverObjFunc = firebase.functions().httpsCallable('createDBUserObject')
      serverObjFunc({
        uid: user.uid,
        email,
        firstName,
        lastName,
        country,
        businessName: businessName || undefined,
        phoneNumber: phoneNumber || undefined,
        USState: USState || '',
        referralCodeApplied: referralCode,
        signupPlatform: Capacitor.getPlatform(),
      })

      trackAppsFlyerEvent({ eventName: 'af_complete_registration' })

      setSubmitted(true)
    } catch (e) {
      log(e)
    }

    setIsLoading(false)
  }

  const isFormValid = () => {
    return valid.email
      && valid.password
      && valid.passwordMatch
      && password.length > 0
      && repeatPassword.length > 0
      && termsAccepted
  }

  const validate = (name, value) => {
    switch (name) {
      case 'password':
        setValid({ ...valid, password: validPasswordRegex.test(value) })
        break
      case 'repeatpassword':
        setValid({ ...valid, passwordMatch: valid.password && password === value })
        break
      default:
        break
    }
  }

  const handleChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    switch (name) {
      case 'password':
        setPassword(value)
        break
      case 'repeatpassword':
        setRepeatPassword(value)
        break
    }

    validate(name, value)
  }

  const SubmitConfirmation = () => {
    return (
      <div className={styles.center}>
        <Text variant={'h5'} text={'We sent you a verification email!'} gutterBottom style={styles.title} align='center'/>
        <Text text={`Please check your inbox and click the link inside.`} align='center' style={styles.message}/>
        <Text text={'After you verify, come back here to continue signing in.'} align='center' style={styles.message}/>

        <Button
          style={styles.submitButton}
          variant='outlined'
          size='large'
          color='secondary'
          text={'Sign in'}
          onClick={() => navigate('/login', { state: {
            initialEmail: email,
            initialPassword: password,
          } })}/>
      </div>
    )
  }


  return (
    <TileWrapper large>
      {isSubmitted ? <SubmitConfirmation/> :
        <>
          <Text variant={'h6'} text={'Set your account password'} align='center' style={styles.heading}/>

          <Grid container
            direction='column'
            justify='center'
            alignItems='center'
          >

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Input
                value={password}
                onChange={handleChange}
                type='password'
                name='password'
                label={'Password'}
                error={!valid.password}
                size='small'
                required
                fullWidth
                autoFocus
              />
              <Input
                value={repeatPassword}
                onChange={handleChange}
                type='password'
                name='repeatpassword'
                error={!valid.passwordMatch}
                label={'Repeat password'}
                size='small'
                required
                fullWidth
              />
              <ul className={`${styles.list} ${valid.password ? '' : styles.listError}`}>
                <Text variant={'caption'} text={'Please make sure your new password'}/>
                <Text variant={'caption'} component='li' text={'consists of upper and lower case letters'}/>
                <Text variant={'caption'} component='li' text={'contains at least one number'}/>
                <Text variant={'caption'} component='li' text={'has a min. length of 12 characters'}/>
              </ul>

              <View style={styles.checkboxLabelWrapper}>
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  name='termsAccepted'
                  color='primary'
                />
                <Text style={styles.acceptTermsText}>* I accept the Stablegains <a target='_blank' href='https://www.stablegains.com/terms-of-use'>Terms of Use</a> and <a target='_blank' href='https://www.stablegains.com/privacy-policy'>Privacy Policy</a>.</Text>
              </View>

              <Button disabled={!isFormValid()} loading={isLoading} style={styles.submitButton} variant='contained' size='large' color='secondary' text={'SIGN UP'} onClick={onSignUp} fullWidth/>

            </Grid>

          </Grid>
        </>
      }
    </TileWrapper>
  )
}

const useStyles = makeStyles((theme) => ({
  message: {
    // opacity: 0.5,
    textAlign: 'center',
  },
  option: {
    color: theme.palette.primary.main,
  },
  heading: {
    marginBottom: theme.spacing(3),
  },
  submitButton: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    alignSelf: 'center',
    textAlign: 'center',
  },
  listError: {
    color: 'red!important',
  },
  list: {
    margin: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: 16,
    color: 'rgba(0, 0, 0, 0.54)',
    opacity: 0.5,
  },
  circularWrapper: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  checkboxLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  acceptTermsText: {
    fontSize: 14,
  },
}))

export default Signup
